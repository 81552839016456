import styles from './refRatingTab.module.css'
import React, {useState} from "react";
import DailyRulesModal from "./DailyRules/DailyRulesModal";

export function RefRatingTab({ data }) {
    // открываем модальное окно с правилами ежедневного рейтинга
    const [isModalOpen, setIsModalOpen] = useState(false);
    const openModal = () => {
        //setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };
    return (
        <section className={styles.section}>
            <div className={styles.titleLine}>
                <div className={styles.title}>{data.refRating?.title}</div>
                <div className={styles.clockBox}>
                    <div className={styles.clockIcon}></div>
                    <p>{data.refRating?.timeRemaining}</p>
                </div>

            </div>
            <div className={styles.description}>{data.refRating?.description}</div>
            <div className={styles.rulesButton} onClick={openModal}>
                <div className={styles.rulesButtonIcon}></div>
                Правила реферального рейтинга
            </div>
            <div className={styles.leaderboard}>
                {(() => {
                    const leaders = data.refRating?.leaders || [];
                    // Сортируем позиции для правильного отображения
                    const sortedLeaders = [
                        leaders.find((l) => l.position === 2), // Второе место
                        leaders.find((l) => l.position === 1), // Первое место
                        leaders.find((l) => l.position === 3), // Третье место
                    ].filter(Boolean); // Убираем `undefined`, если вдруг не все данные есть

                    return sortedLeaders.map((leader) => (
                        <div
                            key={leader.username}
                            className={`${styles.leader} ${
                                leader.position === 1
                                    ? styles.firstPlace
                                    : leader.position === 2
                                        ? styles.secondPlace
                                        : styles.thirdPlace
                            }`}
                        >
                            <div className={styles.tonBox}>
                                <div className={styles.tonIcon}></div>
                                <div className={styles.prize}>{leader.prize}</div>
                            </div>
                            <div className={styles.leaderIconWrapper}>
                                <img
                                    className={`${styles.leaderIcon} ${leader.position === 1 ? styles.leaderIconLarge : ""}`}
                                    src="https://via.placeholder.com/40"
                                    alt="Tournament Banner"
                                />
                                <span className={`${styles.medal} ${leader.position === 1 ? styles.medalLarge : ""}`}>
                        {leader.position === 1
                            ? "🥇"
                            : leader.position === 2
                                ? "🥈"
                                : "🥉"}
                    </span>
                            </div>
                            <div className={styles.userName}>{leader.username}</div>
                            <div className={styles.pointsBox}>
                                <div className={styles.gobletIcon}></div>
                                <div className={styles.points}>{leader.points}</div>
                            </div>
                        </div>
                    ));
                })()}
            </div>
            <div className={styles.leaderList}>
                {data.refRating?.leadersList.map((leader, index) => (
                    <div key={index} className={styles.leaderCard}>
                        <div className={styles.leaderWrapper}>
                            <img
                                src={leader.avatar || "https://via.placeholder.com/40"}
                                alt={leader.username}
                                className={styles.listLeaderIcon}
                            />
                            <div className={styles.leaderInfo}>
                                <div className={styles.leaderName}>
                                    {leader.username}
                                </div>
                                <div className={styles.listPointsBox}>
                                    <div className={styles.listGobletIcon}></div>
                                    <div className={styles.listPoints}>{leader.points}</div>
                                </div>
                            </div>
                        </div>
                        <div className={styles.listPosition}>#{leader.position}</div>
                    </div>
                ))}
            </div>
            <DailyRulesModal
                isOpen={isModalOpen}
                onClose={closeModal}
            />
        </section>
    );
}