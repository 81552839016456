import styles from './tasksScreen.module.css'
import {fetchData} from "../api/api";
import React, {useEffect, useState} from "react";
import {Preloader} from "../Components/Preloader";

export function TasksScreen() {
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);

    const loadData = async () => {
        try {
            const response = await fetchData('/tasks/screen');
            setData(response)
        } catch (error) {
            console.error('Ошибка:', error);
        }
        finally {
            setLoading(false)
        }
    };

    useEffect(() => {
        loadData();
    }, []);

    if (loading) {
        return <Preloader/>;
    }

    // Проверяем, что данные загружены и существуют
    if (!data) {
        return <div className={styles.error}>Ошибка загрузки данных</div>;
    }

    return (
        <div className={styles.tasksScreen}>
            <header className={styles.header}>
                <div className={styles.headerLeft}>
                    <div className={styles.userLogo}>C</div>
                    <span className={styles.userName}>Candiercap</span>
                </div>
                <div className={styles.headerRight}>
                    <div className={styles.balance}>
                        <span className={styles.tonIcon}></span>
                        <span>0,00</span>
                    </div>
                    <div className={styles.balance}>
                        <span className={styles.diamond}></span>
                        <span>600</span>
                    </div>
                </div>
            </header>

            <div className={styles.banner}>
                {/*<img src={data.banner?.imageUrl} alt="Tournament Banner" />*/}
                <img src={data.banner.imageUrl || "https://via.placeholder.com/400x180"} alt="Tournament Banner" />
            </div>

                <div className={styles.vitrina}>
                    <div className={styles.content}>
                        <div className={styles.leftSideContent}>
                            <h1>Получи до 100 000 <span className={styles.icon}>💎</span></h1>
                            <p>Выгодные предложения от наших партнеров</p>
                        </div>
                        <div className={styles.vitrinaImage}></div>
                    </div>
                    <a href="https://www.google.com" className={styles.button}>Смотреть предложения</a>
                </div>

            <div className={styles.tasksBlock}>
                <div className={styles.tasksHeader}>
                    <div className={styles.inviteTitle}>Ежедневные</div>
                    <div className={styles.rewardBlock}>
                        <div className={styles.diamondBlock}>
                            <div className={styles.diamondIcon}></div>
                            <div className={styles.diamondCounter}>0/500</div>
                        </div>
                        <div className={styles.counterBlock}>
                            <div className={styles.checkMarkIcon}></div>
                            <div className={styles.counter}>0/3</div>
                        </div>
                    </div>
                </div>
                <div className={styles.tasksList}>
                    {data.dailyTasks.tasks.map((task, index) => (
                        <div key={index} className={styles.taskCard}>
                            <div className={styles.leftWrapper}>
                                <img
                                    src={task.iconUrl}
                                    alt=""
                                    className={styles.taskIcon}
                                />
                                <div className={styles.taskInfo}>
                                    <div className={styles.taskTitle}>
                                        {task.title}
                                    </div>
                                    <div className={styles.taskSubtitle}>
                                        {task.description}
                                    </div>
                                    <div className={styles.rewardInnerBlock}>
                                        <div className={styles.diamondIcon}></div>
                                        <div className={styles.innerReward}>+{task.reward}</div>
                                    </div>
                                </div>
                            </div>
                            <div className={styles.startButton}>Старт</div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}