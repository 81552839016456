import React, {useState} from 'react';
import styles from './questTab.module.css';
import {AccordionItem} from "./AccordionItem";

export function QuestTab() {
    return (
        <div>
            <div className={styles.tournamentInfo}>
                <img
                    // src="https://via.placeholder.com/300x220"
                    src={require('../assets/images/RatingMeme.png')}
                    alt="Tournament"
                    className={styles.tournamentImage}
                />
                <div className={styles.blockTitle}>Объясняем, как заработать до 10 TON</div>
                <div className={styles.blockSubtitle}>
                    <p>Тебе необходимо набрать 2 000 очков за игру.</p>
                    <p>На твоё вознаграждение влияет количество просмотренной рекламы и выполненных заданий.</p>
                </div>
                <div className={styles.typeRow}>
                    <div className={styles.subRow}>
                        <div className={styles.subRow1}>Тип</div>
                        <div className={styles.subRow2}>Разовое</div>
                    </div>
                    <div className={styles.subRow}>
                        <div className={styles.subRow1}>Приз</div>
                        <div className={styles.subRow2}>
                            <div className={styles.tonIcon}></div>
                            <div>До 10 TON</div>
                        </div>
                    </div>
                </div>
            </div>

            <div className={styles.accordion}>
                <AccordionItem
                    title="Кажется я выиграл, что делать?"
                    content="Посмотрите ваш баланс, если вы действительно выиграли деньги, то ваш баланс будет пополнен."
                />
                <AccordionItem
                    title="Хочу получить TON, очень сильно"
                    content="Следуйте инструкциям, чтобы получить TON, ознакомьтесь с правилами."
                />
                <AccordionItem
                    title="Что делать если я не подписался"
                    content="Пожалуйста, подпишитесь для завершения участия в турнире."
                />
            </div>

            <button className={styles.tournamentButton}>Перейти к турниру</button>
        </div>
    )
}