import styles from './preloader.module.css'

export function Preloader() {
    return (
        <div className={styles.preloaderScreen}>
            <div className={styles.tonImage}></div>
            <div className={styles.title}>Хватит кликать, пора зарабатывать</div>
            <div className={styles.subTitle}>Играй в игры и получи до 30 TON</div>
        </div>
    );
}