import React, {useState} from 'react';
import styles from './gameRulesScreen.module.css';
import {RatingTab} from "./RatingTab";
import {QuestTab} from "./QuestTab";
import {useNavigate} from "react-router-dom";

export function RulesScreen() {
    const [activeTab, setActiveTab] = useState('Рейтинг'); // Изначально активная вкладка
    let navigate = useNavigate()
    //переключение вкладок
    const renderTabContent = () => {
        switch (activeTab) {
            case 'Рейтинг':
                return <RatingTab />;
            case 'Квест':
                return <QuestTab />;
            default:
                return null;
        }
    };

    function closeHandle() {
        navigate(-1)
    }
    return (
        <div className={styles.rulesScreen}>
            <header className={styles.cardHeader}>
                <h2>Правила игры</h2>
                <button className={styles.closeButton} onClick={closeHandle}>X</button>
            </header>

            <nav className={styles.tabMenu}>
                <div className={`${styles.tab} ${activeTab === 'Рейтинг' ? styles.active : ''}`} onClick={() => setActiveTab('Рейтинг')}>Рейтинг</div>
                <div className={`${styles.tab} ${activeTab === 'Квест' ? styles.active : ''}`} onClick={() => setActiveTab('Квест')}>Квест</div>
            </nav>
            {renderTabContent()}
        </div>
    );
}
