import styles from './startScreen.module.css'
import React, {useEffect, useState} from "react";
import { fetchData } from '../api/api';
import {useNavigate} from "react-router-dom";
import paths from "../router";
import {Preloader} from "../Components/Preloader";
import DailyRewardModal from "./DailyReward/DailyRewardModal"

export function StartScreen() {
    const [progress, setProgress] = useState(0); // Состояние для прогресса
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [data, setData] = useState(null);
    let navigate = useNavigate()

    const [isModalOpen, setIsModalOpen] = useState(false);
    const openModal = () => {
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };

    const loadData = async () => {
        setLoading(true); // Устанавливаем состояние загрузки
        setError(null);
        try {
            const result = await fetchData('/user'); // Выполняем запрос
            setData(result); // Обновляем состояние данными
        } catch (err) {
            setError(err.message); // Обрабатываем ошибку
        } finally {
            setLoading(false); // Выключаем прелоадер
        }
    };
    useEffect(() => {
        loadData()
    }, []);

    // обновляем экран с параметрами из запроса
    useEffect(() => {
        updateProgress(52)
        console.log('Data loaded:', data);
        if (data) {
            if (data.dailyReward?.shouldShow) {
                openModal(); // Открываем модалку, если должно быть показано
            }
        } else {
            console.error('data не определены');
        }
        console.log(data)
    }, [data]);

    // Функция для установки прогресса
    const updateProgress = () => {
        setProgress((prevProgress) => {
            const newProgress = prevProgress + 10;
            return newProgress > 100 ? 100 : newProgress; // Ограничение прогресса на 100%
        });
    };

    function getTonHandle() {
        navigate(paths.rules)
    }

    function handlePlayGame(index) {
        //navigate(paths.gameDetail, {state: {gameData: data, gameIndex: index}})
        const gameUrl = 'http://104.248.181.0:5555/game-stick-hero/index.html'
        navigate(paths.gamePortal, {state: {gameUrl: gameUrl}})
    }

    if (loading) {
        return <Preloader/>;
    }

    // Проверяем, что данные загружены и существуют
    if (!data) {
        return <div className={styles.error}>Ошибка загрузки данных</div>;
    }

    return (
        <div className={styles.startScreen}>
        {/* Header */}
            <header className={styles.header}>
                <div className={styles.headerLeft}>
                    <img
                        src={data.avatar || "https://via.placeholder.com/40"}
                        alt="UserAvatar"
                        className={styles.userLogo}
                    >
                    </img>
                    <span className={styles.userName}>{data.username}</span>
                </div>
                <div className={styles.headerRight}>
                    <div className={styles.balance}>
                        <span className={styles.tonIcon}></span>
                        <span>{data.balance.ton}</span>
                    </div>
                    <div className={styles.balance}>
                        <span className={styles.diamond}></span>
                        <span>{data.balance.coins}</span>
                    </div>
                </div>
            </header>

            {/*<nav className={styles.tabMenu}>*/}
            {/*    {data.tabs?.map((tab) => (*/}
            {/*        <div*/}
            {/*            key={tab}*/}
            {/*            className={`${styles.tab} ${activeTab === tab ? styles.active : ""}`}*/}
            {/*            onClick={() => setActiveTab(tab)}*/}
            {/*        >*/}
            {/*            {tab}*/}
            {/*        </div>*/}
            {/*    ))}*/}
            {/*</nav>*/}

            {/* Content */}
            <main className={styles.mainContent}>
                {/* How to get TON section */}
                <div className={styles.tonInfo} onClick={getTonHandle}>
                    <button className={styles.tonButton}>
                        <div className={styles.tonButtonContent}>
                            <div className={styles.tonButtonIcon}></div>
                            <div className={styles.tonButtonTextSide}>
                                <div className={styles.tonButtonUpperText}>Правила игры</div>
                                <div className={styles.tonButtonLowerText}>Объясняем как заработать TON</div>
                            </div>
                            <div className={styles.tonButtonChevron}></div>
                        </div>
                    </button>
                </div>

                {/* Game promotion section */}
                {data.games.map((game, index) => (
                    <div className={styles.gameCard}>
                        <div className={styles.gameInfo}>
                            <div className={styles.gameInfoUpperText}>{game.title}</div>
                            <div className={styles.gameInfoLowerText}>{game.subtitle}</div>
                        </div>
                        <div className={styles.gameScoreBlock}>
                            <div className={styles.gameScoreBlockTextLine}>
                                <div className={styles.gameScoreBlockTitle}>Получено очков</div>
                                <div className={styles.gameScoreBlockScores}>
                                    <div>102</div>
                                    /
                                    <div>200</div>
                                </div>
                            </div>
                            <div className={styles.progressContainer}>
                                <div
                                    className={styles.progressBar}
                                    style={{ width: `${progress}%` }}
                                ></div>
                            </div>
                        </div>
                        <div className={styles.gameImage}>
                            <div className={styles.tournamentStatsBox}>
                                <div className={styles.tournamentIcon}></div>
                                <div className={styles.tournamentScores}>{game.scores}</div>
                                <div className={styles.tournamentDivider}> • </div>
                                <div className={styles.tournamentRating}>{game.position}</div>
                            </div>
                            <img
                                src={game.gameImage || "https://via.placeholder.com/300x220"}
                                alt=""
                            />
                            <div className={styles.gameInnerInfo}>
                                <div className={styles.gameInnerTitle}>{game.gameName}</div>
                                <div className={styles.gameInnerSubTitle}>{game.gameDescription}</div>
                            </div>
                        </div>
                        <div className={styles.gameFooter}>
                            <div className={styles.gameDetails}>
                                <img
                                    src={game.gameIcon}
                                    alt="Game Icon"
                                    className={styles.gameIcon}
                                />
                                <div>
                                    <div className={styles.gameName}>{game.gameName}</div>
                                    <div className={styles.tryCounterBlock}>
                                        <div className={styles.tryCounterIcon}></div>
                                        <div className={styles.tryCounter}>{game.attemptsLeft} попыток</div>
                                    </div>
                                </div>
                            </div>
                            <button className={styles.playButton} onClick={() => handlePlayGame(index)}>Играть</button>
                        </div>
                    </div>
                ))}

            </main>

                <DailyRewardModal
                    data={data}
                    isOpen={isModalOpen}
                    onClose={closeModal}
                />

        </div>
    );
}