import styles from './gameDetailScreen.module.css'
import React, {useEffect, useState} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import paths from "../../router";

export function GameDetailScreen() {
    const location = useLocation()
    const navigate = useNavigate()

    function handlePlayGame() {
        navigate(paths.gamePortal)
    }

    let game = location.state?.gameData;
    let index = location.state?.gameIndex

    // Если данных нет, показываем загрузку или ошибку
    if (!game) {
        return (
            <div className={styles.gameScreen}>
                <div className={styles.content}>
                    <p>Данные не найдены. Пожалуйста, попробуйте позже.</p>
                </div>
            </div>
        );
    }

    return (
        <div className={styles.gameScreen}>
            <header className={styles.header}>
                <div className={styles.headerLeft}>
                    <div className={styles.userLogo}>C</div>
                    <span className={styles.userName}>{game.username}</span>
                </div>
                <div className={styles.headerRight}>
                    <div className={styles.balance}>
                        <span className={styles.tonIcon}></span>
                        <span>{game.balance.ton}</span>
                    </div>
                    <div className={styles.balance}>
                        <span className={styles.diamond}></span>
                        <span>{game.balance.coins}</span>
                    </div>
                </div>
            </header>
            <div className={styles.gameCard}>
                <div className={styles.gameImage}>
                    <div className={styles.tournamentStatsBox}>
                        <div className={styles.tournamentIcon}></div>
                        <div className={styles.tournamentScores}>{game.scores}</div>
                        <div className={styles.tournamentDivider}> • </div>
                        <div className={styles.tournamentRating}>{game.position}</div>
                    </div>
                    <img
                        src={game.games[index]?.gameImage || "https://via.placeholder.com/300x220"}
                        alt=""
                    />
                    <div className={styles.gameInnerInfo}>
                        <div className={styles.gameInnerTitle}>{game.games[index]?.gameName}</div>
                        <div className={styles.gameInnerSubTitle}>{game.games[index]?.gameDescription}</div>
                    </div>
                </div>
                <div className={styles.gameFooter}>
                    <div className={styles.gameDetails}>
                        <img
                            src={game.games[index]?.gameIcon}
                            alt="Game Icon"
                            className={styles.gameIcon}
                        />
                        <div>
                            <div className={styles.gameName}>{game.games[index]?.gameName}</div>
                            <div className={styles.tryCounterBlock}>
                                <div className={styles.tryCounterIcon}></div>
                                <div className={styles.tryCounter}>{game.games[index]?.attemptsLeft} попыток</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={styles.gameDescription}>{game.games[index]?.gameDescription}</div>
                <button className={styles.playButton} onClick={handlePlayGame}>Играть</button>
            </div>
        </div>
    );
}