import styles from "./onboarding.module.css"


export function Onboarding() {
    return (
        <div className={styles.preloaderScreen}>
            <div className={styles.tonImage}></div>
            <div className={styles.title}>Хватит кликать, пора зарабатывать</div>
            <div className={styles.subTitle}>Играй в игры и получи до 30 TON</div>
        </div>
    );
}