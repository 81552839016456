import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { FriendsScreen } from "../Friends/FriendsScreen";
import { TasksScreen } from "../Tasks/TasksScreen";
import { StartScreen } from "../Start/StartScreen";
import { RatingScreen } from "../Rating/RatingScreen";
import { TournamentScreen } from "../Tournament/TournamentScreen"
import styles from "./mainScreen.module.css"
import { ReactComponent as GamingPadIcon } from "../assets/icons/gamingPadTabIcon.svg"
import { ReactComponent as RatingIcon } from "../assets/icons/ratingTabIcon.svg"
import { ReactComponent as FriendsIcon } from "../assets/icons/friendsTabIcon.svg"
import { ReactComponent as TasksIcon } from "../assets/icons/tasksTabIcon.svg"
import { ReactComponent as TournamentIcon } from "../assets/icons/tournamentTabIcon.svg"
import {postData} from "../api/api";

export function MainScreen() {
    const [urlQuery, setUrlQuery] = useSearchParams();

    const sendUser = async () => {
        try {
            const result = await postData('/user/onboarding');
            return result.status === 200;
        } catch (err) {
            console.log(err)
            return false;
        }
    };

    useEffect(() => {
        sendUser()
    }, []);

    function currentTabIndex() {
        const tabIndex = urlQuery.get("tab");
        return tabIndex !== null ? +tabIndex : 2; // Если нет параметра "tab", по умолчанию будет 0
    }

    function setCurrentTabIndex(newIndex) {
        const idx = +newIndex;
        if (idx !== undefined && idx >= 0 && idx <= 4 && idx !== currentTabIndex()) {
            // Сохранение только параметра "tab" без потери других параметров
            setUrlQuery({ tab: newIndex }, { replace: true });
        }
    }

    if (currentTabIndex() === undefined) {
        setCurrentTabIndex(2);
        return null;
    }

    let content = <div className={styles.tabPlaceholder}></div>;
    switch (currentTabIndex()) {
        case 0:
            content = <FriendsScreen />;
            break;
        case 1:
            content = <TasksScreen />;
            break;
        case 2:
            content = <StartScreen />;
            break;
        case 3:
            content = <RatingScreen />;
            break;
        case 4:
            content = <TournamentScreen />;
            break;
        default:
            content = <div className={styles.tabPlaceholder}></div>;
    }



    return (
        <div className={styles.appScreen}>

            <div className={styles.tabContent}>{content}</div>
            <div className={styles.tabBar}>
                <button
                    className={`${styles.tabBarButton} ${currentTabIndex() === 0 ? styles.active : ""}`}
                    onClick={() => setCurrentTabIndex(0)}
                >
                    <FriendsIcon className={currentTabIndex() === 0 ? styles.activeIcon : styles.nonActiveIcon}/>
                    <span>Друзья</span>
                </button>
                <button
                    className={`${styles.tabBarButton} ${currentTabIndex() === 1 ? styles.active : ""}`}
                    onClick={() => setCurrentTabIndex(1)}
                >
                    <TasksIcon className={currentTabIndex() === 1 ? styles.activeIcon : styles.nonActiveIcon}/>
                    <span>Задачи</span>
                </button>
                <button
                    className={`${styles.tabBarButton} ${currentTabIndex() === 2 ? styles.active : ""}`}
                    onClick={() => setCurrentTabIndex(2)}
                >
                    <GamingPadIcon className={currentTabIndex() === 2 ? styles.activeIcon : styles.nonActiveIcon}/>
                    <span>Главная</span>
                </button>
                <button
                    className={`${styles.tabBarButton} ${currentTabIndex() === 3 ? styles.active : ""}`}
                    onClick={() => setCurrentTabIndex(3)}
                >
                    <RatingIcon className={currentTabIndex() === 3 ? styles.activeIcon : styles.nonActiveIcon}/>
                    <span>Рейтинг</span>
                </button>
                <button
                    className={`${styles.tabBarButton} ${currentTabIndex() === 4 ? styles.active : ""}`}
                    onClick={() => setCurrentTabIndex(4)}
                >
                    <TournamentIcon className={currentTabIndex() === 4 ? styles.activeIcon : styles.nonActiveIcon}/>
                    <span>Турнир</span>
                </button>
            </div>
        </div>
    );
}
