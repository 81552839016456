import React, { useEffect, useState } from 'react';
import {useLocation} from "react-router-dom";

export function GameScreen({ gameUrl }) {
    const location = useLocation()
    const iframeUrl = `${location.state.gameUrl}?telegramUserData=${window.Telegram.WebApp.initData}`;

    // const [iframeUrl, setIframeUrl] = useState(null);

    useEffect(() => {
        // Пример: Здесь вы могли бы делать запрос к API, чтобы получить URL для игры
        // setIframeUrl(gameUrl); // Устанавливаем URL игры, если он передан как пропс.

        // Если URL игры передается через пропсы, мы сразу его используем.
        // setIframeUrl(gameUrl);
    }, [gameUrl]);

    if (!iframeUrl) {
        return <div>Загрузка...</div>; // Показываем индикатор загрузки, если URL не получен
    }

    return (
        <div style={{ width: '100%', height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <iframe
                src={iframeUrl}
                width="100%"
                height="100%"
                title="Game"
                frameBorder="0"
                style={{ border: 'none' }}
            />
        </div>
    );
}
