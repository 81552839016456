import React, {useState} from 'react';
import styles from './ratingTab.module.css';
import {AccordionItem} from "./AccordionItem";

export function RatingTab() {
    return (
        <div>
            <div className={styles.tournamentInfo}>
                <img
                    // src="https://via.placeholder.com/300x220"
                    src={require('../assets/images/RatingMeme.png')}
                    alt="Tournament"
                    className={styles.tournamentImage}
                />
                <div className={styles.blockTitle}>Как получить TON за  рейтинг?</div>
                <div className={styles.blockSubtitle}>Ежедневный</div>
                    <ul>
                        <li>Набери больше всех очков за 24 часа.</li>
                        <li>Используй для этого умножение очков и вторую жизнь после каждой игры.</li>
                        <li>Выполняй задания, чтобы сыграть больше игр и войти в топ рейтинга.</li>
                        <li>Проверяй результаты в разделе “Рейтинг”.</li>
                    </ul>
                <div className={styles.blockSubtitle}>Рефферальный</div>
                <ul>
                    <li>Пригласи больше всех друзей за 7 дней, которые активируются.</li>
                </ul>
                    <div className={styles.typeRow}>
                        <div className={styles.subRow}>
                            <div className={styles.subRow1}>Тип</div>
                            <div className={styles.subRow2}>Регулярное</div>
                        </div>
                        <div className={styles.subRow}>
                            <div className={styles.subRow1}>Игровой фонд</div>
                            <div className={styles.subRow2}>
                                <div className={styles.tonIcon}></div>
                                <div>10 TON</div>
                            </div>
                        </div>
                    </div>
            </div>

            <div className={styles.accordion}>
                <AccordionItem
                    title="Кажется я выиграл, что делать?"
                    content="Посмотрите ваш баланс, если вы действительно выиграли деньги, то ваш баланс будет пополнен."
                />
                <AccordionItem
                    title="Хочу получить TON, очень сильно"
                    content="Следуйте инструкциям, чтобы получить TON, ознакомьтесь с правилами."
                />
                <AccordionItem
                    title="Что делать если я не подписался"
                    content="Пожалуйста, подпишитесь для завершения участия в турнире."
                />
            </div>

            <button className={styles.tournamentButton}>Перейти к турниру</button>
        </div>
    )
}