import React, { useEffect, useState } from 'react';
import styles from './dailyRewardModal.module.css';
import { postData } from '../../api/api';

const DailyRewardModal = ({ data, isOpen, onClose }) => {
    const rewards = data.dailyReward.rewards

    //     [
    //     { "day": 1, "reward": 100, "isClaimed": true },
    //     { "day": 2, "reward": 200, "isClaimed": false },
    //     { "day": 3, "reward": 300, "isClaimed": false },
    //     { "day": 4, "reward": 400, "isClaimed": false },
    //     { "day": 5, "reward": 500, "isClaimed": false },
    //     { "day": 6, "reward": 600, "isClaimed": false },
    //     { "day": 7, "reward": 700, "isClaimed": false }
    // ];

    // Текущий день
    const currentDay = data?.currentDay || 1;

    const [sliderIndex, setSliderIndex] = useState(currentDay - 1);  // Индекс для слайдера
    const [isModalVisible, setIsModalVisible] = useState(isOpen); // Состояние видимости модалки

    // Функция для создания карточек
    const getCardsToDisplay = () => {
        let startDay = Math.max(0, sliderIndex - 1);  // Обеспечиваем, чтобы показывались 3 карточки
        let endDay = Math.min(startDay + 3, rewards.length);  // Ограничиваем количество карточек

        return rewards.slice(startDay, endDay).map((reward) => {
            const isActive = reward.day === currentDay;
            const isClaimed = reward.isClaimed;

            return (
                <div
                    key={reward.day}
                    className={styles.card}
                >
                    <div className={`${styles.innerCard} ${isActive ? styles.active : ''} ${isClaimed ? styles.claimed : ''}`}>
                        {isClaimed ? (<div className={styles.checkMark}></div>) : (<div></div>)}
                        <div className={`${isClaimed || isActive ? styles.chrystal : styles.chrystalGray}`}></div>
                        <div className={`${isClaimed || isActive ? styles.reward : styles.rewardGray}`}>{reward.reward}</div>
                    </div>
                    <div className={`${isClaimed || isActive ? styles.cardContent : styles.cardContentGrey}`}>День {reward.day}</div>
                </div>
            );
        });
    };

    // Используем useEffect для отслеживания изменения currentDay
    useEffect(() => {
        setSliderIndex(currentDay - 1);
    }, [currentDay]);

    useEffect(() => {
        setIsModalVisible(isOpen)
    }, [isOpen]);

    const postReward = async () => {
        try {
            const result = await postData('/rewards');
            return result.status === 200;
        } catch (err) {
            return false;
        }
    };

    // Обработчик для кнопки "Забрать награду"
    const handleClose = async () => {
        const isSuccess = await postReward();
        if (isSuccess) {
            onClose();
        } else {
            console.log("Не удалось забрать награду.");
        }
        onClose()
    };

    if (!isModalVisible) return null;

    return (
        <div className={styles.modalOverlay}>
            <div className={styles.modalContent}>
                <div className={styles.icon}></div>
                <div className={styles.title}>Ваша ежедневная награда</div>
                <div className={styles.description}>Возвращайся завтра, чтобы получить еще больше. Пропуск дня обнулит прогресс</div>

                {/* Слайдер */}
                <div className={styles.sliderContainer}>
                    {getCardsToDisplay()}
                </div>

                {/* Кнопка для закрытия модалки */}
                <div className={styles.button} onClick={handleClose}>
                    Забрать награду
                </div>
            </div>
        </div>
    );
};

export default DailyRewardModal;
