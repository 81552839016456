import React from 'react';
import styles from './dailyRulesModal.module.css';

const DailyRulesModal = ({ isOpen, onClose }) => {
    if (!isOpen) return null;

    return (
        <div className={styles.modalOverlay}>
            <div className={styles.modalContent}>
                <div className={styles.closeButton} onClick={onClose}></div>
                <div className={styles.goblet}></div>
                <div className={styles.title}>Правила ежедневного рейтинга</div>
                <div className={styles.description}>Играй в игры, выполняй задания и возглавь таблицу лидеров. </div>
                <div className={styles.description}>Каждый день победители ежедневного рейтинга получают до
                    <div className={styles.prize}>3 TON</div>
                </div>
                <div className={styles.button} onClick={onClose}>
                    Продолжить
                </div>
            </div>
        </div>
    );
};

export default DailyRulesModal;