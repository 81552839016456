import React, { useEffect, useState } from 'react';
import styles from './ratingScreen.module.css';

import {DailyRatingTab} from "./DailyRatingTab";
import {RefRatingTab} from "./RefRatingTab";
import { fetchData } from '../api/api';
import {Preloader} from "../Components/Preloader";

export function RatingScreen() {
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [activeTab, setActiveTab] = useState("Ежедневный");

    const loadData = async () => {
        try {
            const data = await fetchData('/rating');
            setData(data)
        } catch (error) {
            console.error('Ошибка загрузки данных:', error);
        } finally {
            setLoading(false)
        }
    };
    useEffect(() => {
        loadData();
    }, []);

    if (loading) {
        return <Preloader/>;
    }

    // Проверяем, что данные загружены и существуют
    if (!data) {
        return <div className={styles.error}>Ошибка загрузки данных</div>;
    }
    //переключение вкладок
    const renderTabContent = () => {
        switch (activeTab) {
            case 'Ежедневный':
                return <DailyRatingTab data={data}/>;
            case 'Реферальный':
                return <RefRatingTab data={data}/>;
            default:
                return null;
        }
    };

    return (
        <div className={styles.ratingScreen}>
            <header className={styles.header}>
                <div className={styles.headerLeft}>
                    <div className={styles.userLogo}></div>
                    <span className={styles.userName}>{data.user.username}</span>
                </div>
                <div className={styles.headerRight}>
                    <div className={styles.balance}>
                        <span className={styles.tonIcon}></span>
                        <span>{data.user.balanceTON}</span>
                    </div>
                    <div className={styles.balance}>
                        <span className={styles.diamond}></span>
                        <span>{data.user.balanceGems}</span>
                    </div>
                </div>
            </header>

            <div className={styles.notification}>
                <div className={styles.notificationWrapper}>
                    <img
                        className={styles.notificationIcon}
                        src="https://via.placeholder.com/40"
                        alt="Tournament Banner"
                    />
                    <div className={styles.goldMedal}></div>
                </div>
                <div className={styles.notificationText}>
                    <div className={styles.notificationUpperLine}>
                        <div>{data.notification?.username}</div>
                    </div>
                    <div className={styles.notificationMessage}>{data.notification?.message}</div>
                </div>
                <div className={styles.notificationBalance}>
                    <span className={styles.tonIcon}></span>
                    <span>{data.notification?.reward}</span>
                </div>

            </div>

            <div className={styles.banner}>
                {/*<img src={data.banner?.image} alt="Tournament Banner" />*/}
                <img src={data.banner.image} alt="Tournament Banner" />
            </div>

            <nav className={styles.tabMenu}>
                {data.tabs?.map((tab) => (
                    <div
                        key={tab}
                        className={`${styles.tab} ${activeTab === tab ? styles.active : ""}`}
                        onClick={() => setActiveTab(tab)}
                    >
                        {tab}
                    </div>
                ))}
            </nav>
            {renderTabContent()}
        </div>
    );
}
