import styles from './tournamentScreen.module.css'
import {Preloader} from "../Components/Preloader";
import React, {useEffect, useState} from "react";
import {fetchData} from "../api/api";

export function TournamentScreen() {
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);

    const loadData = async () => {
        try {
            const response = await fetchData('/tournament');
            setData(response)
        } catch (error) {
            console.error('Ошибка:', error);
        }
        finally {
            setLoading(false)
        }
    };

    useEffect(() => {
        //loadData();
    }, []);

    if (loading) {
        //return <Preloader/>;
    }

    // Проверяем, что данные загружены и существуют
    // if (!data) {
    //     return <div className={styles.error}>Ошибка загрузки данных</div>;
    // }

    return (
        <div className={styles.tournamentScreen}>
            <header className={styles.header}>
                <div className={styles.headerLeft}>
                    <div className={styles.userLogo}>C</div>
                    <span className={styles.userName}>Candiercap</span>
                </div>
                <div className={styles.headerRight}>
                    <div className={styles.balance}>
                        <span className={styles.tonIcon}></span>
                        <span>0,00</span>
                    </div>
                    <div className={styles.balance}>
                        <span className={styles.diamond}></span>
                        <span>600</span>
                    </div>
                </div>
            </header>
            <div className={styles.content}>
                <div className={styles.drinkerImage}></div>
                <div className={styles.title}>Скоро здесь появятся турниры!</div>
                <div className={styles.subTitle}>Они позволят зарабатывать до 3 TON ежедневно, соревнуясь с другими игроками</div>
            </div>
        </div>
    );
}