const BASE_URL = '/api';
const telegramInitData = window.Telegram.WebApp.initData;

export const postData = async (endpoint, headersParams = {}) => {
    try {
        const url = `${BASE_URL}${endpoint}`;
        const headers = new Headers({
            telegramUserData: telegramInitData,
            ...headersParams
        });

        const response = await fetch(url, {
            method: 'POST',
            headers: headers
        });

        if (!response.ok) {
            throw new Error('Ошибка при отправке данных');
        }

        return await response.json();
    } catch (error) {
        throw error;
    }
};

export const fetchData = async (endpoint, headersParams = {}) => {
    try {
        const url = `${BASE_URL}${endpoint}`;
        const headers = new Headers({
            telegramUserData: telegramInitData,
            ...headersParams
        });

        const response = await fetch(url, {
            method: 'GET',
            headers: headers
        });

        if (!response.ok) {
            throw new Error('Ошибка при получении данных');
        }

        return await response.json();
    } catch (error) {
        throw error;
    }
};
