import './App.css';
import { Route, Routes } from 'react-router-dom';
import { MainScreen } from "./Main/MainScreen";
import paths from './router';
import { RulesScreen } from "./GameRules/GameRulesScreen";
import React from "react";
import {TMA} from "./Components/TMA"
import {GameDetailScreen} from "./Start/GameDetail/GameDetailScreen";
import {GameScreen} from "./Start/GameScreen/GameScreen";
import {Onboarding} from "./Onboarding/Onboarding";

function App() {
    return (
        <Routes>
            <Route path={paths.base} element={<MainScreen />} />
            <Route path={paths.rules} element={<RulesScreen/>} />
            <Route path={paths.gameDetail} element={<GameDetailScreen/>} />
            <Route path={paths.gamePortal} element={<GameScreen/>} />
            <Route path={paths.onboarding} element={<Onboarding/>} />
            <Route
                path="*"
                element={
                    <div>
                        <h1>Страница не найдена</h1>
                    </div>
                }
            />
        </Routes>
    );
}
export default App;

