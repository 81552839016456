import React, { useState } from 'react';
import styles from './accordionItem.module.css';

export function AccordionItem({ title, content }) {
    const [isOpen, setIsOpen] = useState(false);

    return (
        <div className={styles.accordionItem}>
            <div className={styles.accordionHeader} onClick={() => setIsOpen(!isOpen)}>
                <div className={styles.title}>
                    <span className={styles.icon}></span>
                    {title}
                </div>
                <span className={styles.toggle}>{isOpen ? <div className={styles.minusIcon}></div> : <div className={styles.plusIcon}></div>}</span>
            </div>
            <div
                className={styles.accordionContent}
                style={{maxHeight: isOpen ? '1200px' : '0px' }}
            >
                <p>{content}</p>
            </div>
        </div>
    );
}
