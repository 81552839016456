import styles from './friends.module.css'
import React, {useEffect, useState} from "react";
import {fetchData} from "../api/api";
import {Preloader} from "../Components/Preloader";

export function FriendsScreen() {
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [friends, setFriends] = useState(0); // количество друзей

    const loadData = async () => {
        try {
            const response = await fetchData('/friends');
            setData(response)
        } catch (error) {
            console.error('Ошибка:', error);
        }
        finally {
            setLoading(false)
        }
    };


    useEffect(() => {
        loadData();
    }, []);

    useEffect(() => {
        setFriends(4)
    }, []);


    const friendsList = [
        {
            name: 'Иван Петров',
            avatar: "https://via.placeholder.com/40",
            status: 'Активен'
        },
        {
            name: 'Мария Смирнова',
            avatar: "https://via.placeholder.com/40",
            status: 'Неактивен'
        },
        {
            name: 'Алексей Иванов',
            avatar: "https://via.placeholder.com/40",
            status: 'Активен'
        },
        {
            name: 'Иван Петров',
            avatar: "https://via.placeholder.com/40",
            status: 'Активен'
        },
        {
            name: 'Мария Смирнова',
            avatar: "https://via.placeholder.com/40",
            status: 'Неактивен'
        },
        {
            name: 'Алексей Иванов',
            avatar: "https://via.placeholder.com/40",
            status: 'Активен'
        },
        {
            name: 'Иван Петров',
            avatar: "https://via.placeholder.com/40",
            status: 'Активен'
        },
        {
            name: 'Мария Смирнова',
            avatar: "https://via.placeholder.com/40",
            status: 'Неактивен'
        },
        {
            name: 'Алексей Иванов',
            avatar: "https://via.placeholder.com/40",
            status: 'Активен'
        },
        {
            name: 'Иван Петров',
            avatar: "https://via.placeholder.com/40",
            status: 'Активен'
        },
        {
            name: 'Мария Смирнова',
            avatar: "https://via.placeholder.com/40",
            status: 'Неактивен'
        },
        {
            name: 'Алексей Иванов',
            avatar: "https://via.placeholder.com/40",
            status: 'Активен'
        },
        {
            name: 'Алексей Cеливанов',
            avatar: "https://via.placeholder.com/40",
            status: 'Неактивен'
        }
    ];

    const totalFriends = friendsList.length;
    const activeFriends = friendsList.filter(friend => friend.status === 'Активен').length;
    if (loading) {
        return <Preloader/>;
    }

    // Проверяем, что данные загружены и существуют
    if (!data) {
        return <div className={styles.error}>Ошибка загрузки данных</div>;
    }
    return (
        <div className={styles.friendsScreen}>
            {/* Header */}
            <header className={styles.header}>
                <div className={styles.headerLeft}>
                    <div className={styles.userLogo}>C</div>
                    <span className={styles.userName}>Candiercap</span>
                </div>
                <div className={styles.headerRight}>
                    <div className={styles.balance}>
                        <span className={styles.tonIcon}></span>
                        <span>0,00</span>
                    </div>
                    <div className={styles.balance}>
                        <span className={styles.diamond}></span>
                        <span>600</span>
                    </div>
                </div>
            </header>

            <div className={styles.banner}>
                {/*<img src={data.banner?.imageUrl} alt="Tournament Banner" />*/}
                <img src="https://via.placeholder.com/400x160" alt="Tournament Banner" />
            </div>

            {/* Content */}
            <main className={styles.mainContent}>
                <div className={styles.inviteFriendsBlock}>
                    <div className={styles.inviteFriendsHeader}>
                        <div className={styles.inviteTitle}>Приглашай друзей и играйте вместе</div>
                        <div className={styles.inviteSubtitle}>Награда зачислится автоматически после того, как друг сыграет минимум 2 игры</div>
                    </div>
                    <div className={styles.inviteFriendsPrizes}>
                        <div className={styles.userPriceLine}>
                            <div className={styles.prizeTitle}>Твоя награда</div>
                            <div className={styles.prize}>
                                <div className={styles.prizeItem}>200</div>
                                <div className={styles.diamondIcon}></div>
                            </div>
                        </div>
                        <div className={styles.friendPriceLine}>
                            <div className={styles.prizeTitle}>Награда друга</div>
                            <div className={styles.prize}>
                                <div className={styles.prizeItem}>400</div>
                                <div className={styles.diamondIcon}></div>
                            </div>
                        </div>
                    </div>
                    <div className={styles.inviteFriendsState}>
                        <div className={styles.userPriceLine}>
                            <div className={styles.prizeTitle}>Получено за приглашения</div>
                            <div className={styles.prize}>
                                <div className={styles.prizeItem}>0</div>
                                <div className={styles.diamondIcon}></div>
                            </div>
                        </div>
                    </div>
                </div>
                { friends === 0 ?
                    /* Оторбражение когда друзей нет */
                    (<div className={styles.inviteFriendsBlock}>
                        <div className={styles.friendsHeader}>
                            <div className={styles.inviteTitle}>Ваши друзья</div>
                            <div className={styles.inviteSubtitle}>Нет друзей онлайн</div>
                        </div>
                        <div className={styles.noFriendsIllustration}></div>
                        <div className={styles.friendsFooter}>
                            <div className={styles.inviteTitle}>Вы еще никого не пригласили</div>
                            <div className={styles.inviteSubtitle}>Вместе вы обретёте настоящую силу</div>
                        </div>
                    </div>) :
                    /* Оторбражение когда друзья есть */
                    (<div className={styles.inviteFriendsBlock}>
                        <div className={styles.friendsHeader}>
                            <div className={styles.inviteTitle}>Ваши друзья</div>
                            <div className={styles.inviteSubtitle}>{activeFriends}/{totalFriends} друзей активны</div>
                        </div>
                        <div className={styles.friendsList}>
                            {friendsList.map((friend, index) => (
                                <div key={index} className={styles.friendCard}>
                                    <img
                                        src={friend.avatar || '/default-avatar.png'}
                                        alt={friend.name}
                                        className={styles.friendIcon}
                                    />
                                    <div className={styles.friendInfo}>
                                        <div className={styles.friendName}>
                                            {friend.name}
                                        </div>
                                        <div className={styles.friendStatus}>
                                            <div className={`${friend.status === "Активен" ? styles.friendStatusIndicatorActive : styles.friendStatusIndicatorNonActive}`}></div>
                                            {friend.status}
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>)
                }
            </main>
            <div className={styles.inviteActionBox}>
                <div className={styles.inviteFriendAction}>Пригласить друга</div>
                <div className={styles.link}></div>
            </div>
        </div>
    );
}